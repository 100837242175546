<template>
  <div>

    <app-step step="1" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-box-open"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Forma de envío</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">

      <hr class="mt-0">

      <div class="row d-flex justify-content-between">
        
        <div class="col-sm-2">
          <b-button v-on:click="go_to(1)"
            variant="white" class="border" block>
            <p class="h1 text-primary mt-2"><i class="fad fa-store"></i></p>
            <p class="small text-muted mb-0">Entrega en sucursal</p>
            <p class="small text-muted">(Locales)</p>
          </b-button>
        </div>

        <div class="col-sm-2">
          <b-button v-on:click="go_to(2)"
            variant="white" class="border" block>
            <p class="h1 text-primary mt-2"><i class="fad fa-hand-holding-box"></i></p>
            <p class="small text-muted mb-0">Entrega en sucursal</p>
            <p class="small text-muted">(Rápida)</p>
          </b-button>
        </div>
        
        <div class="col-sm-2">
          <b-button v-on:click="go_to(3)"
            variant="white" class="border" block>
            <p class="h1 text-primary mt-2"><i class="fad fa-truck"></i></p>
            <p class="small text-muted mb-0">Entrega a domicilio</p>
            <p class="small text-muted">(Paquetería)</p>
          </b-button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ShippingForm',
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    go_to(id) {
      var data = {
        firstname: '',
        lastname: '',
        address: '',
        city: '',
        postcode: '',
        country: '',
        country_id: '',
        zone: '',
        zone_id: '',
        comment: '',
      }

      switch (id) {
        case 1:
          this.$store.commit('set_shipping', { key: 1, code: 'entrega-sucursal', method: 'Entrega en sucursal', total: 0, data })
          this.$router.push('/payment')
          break;

        case 2:
          this.$store.commit('set_shipping', { key: 2, code: 'entrega-rapida', method: 'Entrega rápida en sucursal', total: 0, data })
          this.$router.push('/shipping-fast')
          break;

        case 3:
          this.$store.commit('set_shipping', { key: 3, code: 'entrega-domicilio', method: 'Entrega a domicilio', total: 0, data })
          this.$router.push('/shipping')
          break;
      }
    }
  },
  created() {
    this.$store.commit('set_invoice', { required: false })
  },
}
</script>